import React from 'react'

import {
  StyledAppsLabel,
  StyledButtonsRowContainer,
  StyledGridIcon,
  StyledListIcon,
  StyledProductDate,
  StyledProductDateName,
  StyledProductDateValue,
  StyledProductDates,
  StyledProductTitle,
  StyledToggleButtonContainer,
} from './HomeScreen.style'
import { useSelector } from 'react-redux'
import { ReactComponent as ListIcon } from 'assets/images/icons/list.svg'
import { ReactComponent as GridIcon } from 'assets/images/icons/grid.svg'
import ProductWrapper from 'components/ProductWrapper/ProductWrapper'
import { useTranslation } from 'react-i18next'
import { selectCustomerConfiguration } from 'slices/customerConfigurationSlice'
import { useSetUserConfigurationMutation } from 'services/configuration-api'
import { selectProductsCost } from 'slices/coinsSlice'
import { isProductCoveredBySubscription } from 'common/utils/generic'

const HomeScreen = () => {
  const { t } = useTranslation()
  const customerConfig = useSelector(selectCustomerConfiguration)
  const servicePrices = useSelector(selectProductsCost)
  const coinProducts = ['COINS', 'COINS-SUBSCRIPTION']
  const addressListProducts = ['LEAD', 'MODERNIZERLEAD', 'REPORT', 'INDICES', 'MAPS']
  const batchListProducts = ['AVM_VALUATION']
  const priority =
    customerConfig?.configuration?.priority && JSON.parse(customerConfig?.configuration?.priority)
  const products =
    customerConfig?.products
      ?.filter((product) => !product.other && !coinProducts.includes(product.name.toUpperCase()))
      ?.map((product) => {
        let extraProps = {}
        if (addressListProducts.includes(product.name.toUpperCase())) {
          extraProps = {
            ...extraProps,
            isAddressList: true,
          }
        }
        if (batchListProducts.includes(product.name.toUpperCase())) {
          extraProps = {
            ...extraProps,
            isBatchList: true,
          }
        }
        if (product.billing) {
          if (
            product.subscriptionBased &&
            isProductCoveredBySubscription(product.name, customerConfig)
          ) {
            extraProps = {
              ...extraProps,
              showCheckmarkIcon: true,
            }
          } else if (
            product.subscriptionBased &&
            !product.coinBased &&
            !isProductCoveredBySubscription(product.name, customerConfig)
          ) {
            extraProps = {
              ...extraProps,
              showCartIcon: true,
            }
          }
          if (product.coinBased) {
            extraProps = {
              ...extraProps,
              coinPrice: servicePrices[product.name] || product.coinPrice,
            }
          }
        } else {
          if (product.subscriptionBased || product.coinBased) {
            extraProps = {
              ...extraProps,
              showCheckmarkIcon: true,
            }
          }
        }
        return {
          ...product,
          ...extraProps,
        }
      }) || []

  const sortedProducts = products
    .map((prod) => ({
      ...prod,
      priority: priority ? priority[prod.id] || 999 : null,
    }))
    .sort((a, b) => a.priority - b.priority)

  const viewType = customerConfig?.userConfiguration?.homeScreenView || 'module'
  const productDates =
    (customerConfig?.configuration?.productDates &&
      JSON.parse(customerConfig?.configuration?.productDates)) ||
    []

  const favoritesEnabled = JSON.parse(customerConfig?.configuration?.favoritesEnabled || 'false')
  const favoritesList = JSON.parse(customerConfig?.userConfiguration?.favoritesList || '[]')
  const favoriteProducts = sortedProducts.filter((product) => favoritesList.includes(product.id))
  const otherProducts = sortedProducts.filter((product) => !favoritesList.includes(product.id))

  const [updateUserConfiguration] = useSetUserConfigurationMutation()

  const switchView = (nextView: string) => {
    if (nextView !== viewType) {
      updateUserConfiguration({ name: 'homeScreenView', value: nextView })
    }
  }

  const mapProduct = (product) => {
    if (product?.active) {
      return (
        <ProductWrapper
          key={product.name}
          product={product}
          customerConfig={customerConfig}
          isSingle={products?.filter((item) => item.active).length === 1}
          viewType={viewType}
          favoritesEnabled={favoritesEnabled}
          favoritesList={favoritesList}
        />
      )
    }
    return null
  }

  return (
    (customerConfig && (
      <>
        <StyledToggleButtonContainer>
          <StyledListIcon isActive={viewType === 'list'} onClick={() => switchView('list')}>
            <ListIcon />
          </StyledListIcon>
          <StyledGridIcon isActive={viewType === 'module'} onClick={() => switchView('module')}>
            <GridIcon />
          </StyledGridIcon>
        </StyledToggleButtonContainer>
        <div>
          {favoritesEnabled && favoritesList.length > 0 && (
            <div>
              <StyledAppsLabel style={{ marginTop: 0 }}>
                {t('common.favorite-apps')}
              </StyledAppsLabel>
              <StyledButtonsRowContainer>
                {favoriteProducts.map((product) => mapProduct(product))}
              </StyledButtonsRowContainer>
              <StyledAppsLabel>{t('common.other-apps')}</StyledAppsLabel>
              <StyledButtonsRowContainer>
                {otherProducts.map((product) => mapProduct(product))}
              </StyledButtonsRowContainer>
            </div>
          )}
          {!(favoritesEnabled && favoritesList.length > 0) && (
            <StyledButtonsRowContainer>
              {sortedProducts.map((product) => mapProduct(product))}
            </StyledButtonsRowContainer>
          )}
        </div>
        {!!productDates.length && (
          <StyledProductDates>
            <StyledProductTitle>{t('productDates.title')}</StyledProductTitle>
            {productDates.map((productDate) => (
              <StyledProductDate key={productDate.name}>
                <StyledProductDateName>{t(productDate.translationKey)}</StyledProductDateName>
                <StyledProductDateValue>{productDate.value}</StyledProductDateValue>
              </StyledProductDate>
            ))}
          </StyledProductDates>
        )}
      </>
    )) ||
    null
  )
}

export default HomeScreen
